<template>
  <a-spin :style="backCss" :spinning="loading">
    <div class="statistics-menu-list">
      <a-dropdown
        :trigger="['click']"
        v-for="(item, index) in columns"
        :ref="'statistics-' + index"
        :key="item.dataIndex"
        :style="item.isNum ? '' : 'display: none;'"
        :placement="'topCenter'"
      >
        <div class="statistics">统计<a-icon type="caret-up" /></div>
        <a-menu slot="overlay">
          <a-menu-item v-if="item.isNum" @click="compute('meanNum', item.dataIndex, index, '求和')">
            求和
          </a-menu-item>
          <a-menu-item v-if="item.isNum" @click="compute('sumNum', item.dataIndex, index, '平均')">
            平均
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <a-dropdown v-model="visible" :placement="'bottomRight'" :trigger="['contextmenu']">
      <a-menu slot="overlay">
        <a-menu-item v-for="(item, index) in columns" :key="item.dataIndex">
          <a-checkbox v-model="item.checked" @change="checkChange(index)">
            {{ item.title }}
          </a-checkbox>
        </a-menu-item>
      </a-menu>
      <!-- @filter-change="filterChangeEvent" -->
      <vxe-table
        v-show="tableShow"
        :row-config="{ isCurrent: true, isHover: true, height }"
        ref="xTable1"
        border="none"
        class="mytable-scrollbar"
        row-id="key"
        :column-config="{ resizable: true }"
        @checkbox-change="selectChangeEvent"
        @checkbox-all="selectChangeEvent"
        @filter-visible="filterVisible"
        :footer-method="footerMethod"
        :filter-config="{
          remote: true,
          iconNone: 'iconfont icon-shaixuan icon',
          iconMatch: 'iconfont icon-shaixuan icon filter-product-btn'
        }"
        @toggle-tree-expand="toggleExpandChangeEvent"
        :tree-config="
          groupBy.length
            ? { transform: true, lazy: true, hasChild: 'hasChild', rowField: 'id', loadMethod: loadChildrenMethod }
            : false
        "
        @cell-click="cellClickEvent"
        @filter-change="filterChangeEvent"
        :sort-config="{
          multiple: true,
          orders: ['asc', 'desc'],
          trigger: 'cell',
          sortMethod: customSortMethod,
          defaultSort: { field: copyColumns[0].dataIndex, order: 'desc' },
          iconAsc: 'iconfont icon-32pxshengxu icon',
          iconDesc: 'iconfont icon-32pxjiangxu icon'
        }"
        :data="dataSource"
        show-footer
      >
        <vxe-column align="center" type="checkbox" fixed="left" width="80"> </vxe-column>
        <vxe-column
          v-if="groupBy.length"
          tree-node
          fixed="left"
          width="200"
          :field="groupBy[openIdx]"
          title="分组"
          show-overflow
        >
          <template #default="{ row }">
            <span>
              <span>{{ row.label ? row.label + '（' + row.groupCount + '）' : '' }}</span>
            </span>
          </template>
        </vxe-column>
        <!-- :filters="[]" -->
        <vxe-column
          v-for="record in copyColumns"
          :fixed="record.dataIndex == 'nickName' && tableType == 'userTable' ? 'left' : ''"
          :key="record.dataIndex"
          :field="record.dataIndex"
          :title="record.title"
          :width="record.width"
          show-overflow
          :filters="
            tableType != 'reviewTable' &&
            (record.dataIndex == 'targetType' ||
              record.dataIndex == 'approveStatus' ||
              record.dataIndex == 'departments' ||
              record.dataIndex == 'materialType' ||
              record.dataIndex == 'applyTime' ||
              record.dataIndex == 'materialDepartments' ||
              record.dataIndex == 'positions' ||
              record.dataIndex == 'materialPositions' ||
              record.dataIndex == 'ruleType')
              ? []
              : null
          "
          :sortable="tableType == 'reviewTable' ? true : false"
          :filter-method="
            ({ value, row, column }) => {
              return filterMethod(record.dataIndex, value, row, column)
            }
          "
        >
          <template v-if="record.dataIndex == 'nickName' && tableType == 'userTable'" v-slot="{ row }">
            <template v-if="row.children && row.children.length">
              <i class="tree-node-icon"></i>
            </template>
            <div style="min-height:80px;display:flex;align-items:center">
              <div class="status-img">
                <img
                  v-if="row.userFile.avatar"
                  :src="row.userFile.avatar"
                  style="width:35px;height:35px;border-radius: 50%;"
                  alt=""
                />
                <img v-else src="./../../assets/tx.png" style="width:35px;height:35px;border-radius: 50%;" alt="" />
                <div
                  class="status"
                  :style="
                    row.onlineType == 1
                      ? 'background-color:#24B300'
                      : row.onlineType == 2
                      ? 'background-color:gray'
                      : 'background-color:#ADADAD'
                  "
                ></div>
              </div>
              <span>{{ row.nickName }}</span>
            </div>
          </template>
          <template v-else-if="record.dataIndex == 'user' && tableType == 'approveTable'" v-slot="{ row }">
            <div style="display:flex;align-items:center">
              <div class="status-img">
                <img
                  v-if="row.applyUserAvatar"
                  :src="row.applyUserAvatar"
                  style="width:35px;height:35px;border-radius: 50%;"
                  alt=""
                />
              </div>
              <span>{{ row.applyUserName }}</span>
            </div>
          </template>
          <template v-else-if="record.dataIndex == 'approveUser' && tableType == 'approveTable'" v-slot="{ row }">
            <div style="display:flex;align-items:center">
              <div class="status-img">
                <img
                  v-if="row.processUserAvatar"
                  :src="row.processUserAvatar"
                  style="width:35px;height:35px;border-radius: 50%;"
                  alt=""
                />
              </div>
              <span>{{ row.processUserName }}</span>
            </div>
          </template>
          <template v-else-if="record.dataIndex == 'nickName'" #filter="{ $panel, column }">
            <input v-model="username" @input="e => nickNameFilter(e, $panel, column)" />
          </template>
          <template v-else-if="record.dataIndex == 'platform'" v-slot="{ row }">
            <span>{{ row.platform ? row.platform.platformName : '' }}</span>
          </template>
          <template v-else-if="record.dataIndex == 'approveStatus'" v-slot="{ row }">
            <span>
              <span v-if="row.status == 1">
                <a-icon style="color:green;margin-right:5px" type="check-circle" theme="filled" />已通过
              </span>
              <span v-else-if="row.status == 2">
                <a-icon type="close-circle" style="color:red;margin-right:5px" theme="filled" />未通过
              </span>
              <span v-else>等待中</span>
            </span>
          </template>
          <template v-else-if="record.dataIndex == 'materialType'" v-slot="{ row }">
            <span>{{ row.materialType ? row.materialType.name : '' }}</span>
          </template>
          <template v-else-if="record.dataIndex == 'platformName'" v-slot="{ row }">
            <template v-if="row.children && row.children.length">
              <i class="tree-node-icon"></i>
            </template>
            <span>{{ row.platformName }}</span>
          </template>
          <template v-else-if="record.dataIndex == 'month'" #filter="{ $panel, column }">
            <div style="padding-left:10px;padding-right:50px;">
              <a-tree v-model="checkedKeys" checkable :tree-data="treeData" />
            </div>
            <!-- <input type="type" v-for="(option, index) in column.filters" :key="index" v-model="option.data" @input="$panel.changeOption($event, !!option.data, option)"> -->
          </template>
          <template v-else-if="record.dataIndex == 'productOrder'" v-slot="{ row }">
            <span>{{ row.productOrder }}</span>
          </template>
          <template v-else-if="record.dataIndex == 'productName'" v-slot="{ row }">
            <template v-if="row.children && row.children.length">
              <i class="tree-node-icon"></i>
            </template>
            <span
              class="review-product"
              @click.stop="
                e => {
                  goToProduct(e, row)
                }
              "
              >{{ row.productName }}</span
            >
          </template>
          <template v-else-if="record.dataIndex == 'type'" v-slot="{ row }">
            <span>{{
              row.type == 'TRANSLATOR'
                ? '翻译'
                : row.type == 'BUILDER'
                ? '制作'
                : row.type == 'NOT_LOCAL'
                ? '非本地化'
                : ''
            }}</span>
          </template>
          <template v-else-if="record.dataIndex == 'creatorName'" v-slot="{ row }">
            <span>{{ row.creatorName }}</span>
          </template>
          <template v-else-if="record.dataIndex == 'value'" v-slot="{ row }">
            <div>
              <span v-if="!row.editable">{{ row.value }}</span>
              <a-input v-else-if="!row.groupCount" @click.stop v-model="row.value" />
            </div>
          </template>
          <template v-else-if="record.dataIndex == 'departs'" v-slot="{ row }">
            <a-tag v-for="one in row.departs" :key="one.id">
              {{ one.departName }}
            </a-tag>
            <!--             <template v-if="row.departs.length < 3">
              <div v-for="one in row.departs" :key="one.id">
                <a-tag>
                  {{ one.departName }}
                </a-tag>
              </div>
            </template>
            <a-tooltip v-else color="white" placement="top">
              <template #title>
                <div class="task-tooltip">
                  <a-tag v-for="one in row.departs" :key="one.id">
                    {{ one.departName }}
                  </a-tag>
                </div>
              </template>
              <span :style="row.departs.length > 2 ? 'padding-bottom:10px' : ''">
                <template v-for="(one, oneIndex) in row.departs">
                  <a-tag v-if="oneIndex < 2" :key="one.id">
                    {{ one.departName }}
                  </a-tag>
                </template>
                {{ row.departs.length > 2 ? '...' : '' }}
              </span>
            </a-tooltip> -->
          </template>
          <template v-else-if="record.dataIndex == 'positions'" v-slot="{ row }">
            <a-tag v-for="one in row.positions" :key="one.id">
              {{ one.name || one.positionName }}
            </a-tag>
          </template>
          <template v-else-if="record.dataIndex == 'materialPositions'" v-slot="{ row }">
            <a-tag v-for="one in row.positions" :key="one.id">
              {{ one.name || one.name }}
            </a-tag>
          </template>
          <template v-else-if="record.dataIndex == 'action'" v-slot="{ row }">
            <a @click.stop="goToMaterialList(row)">查看</a>
            <defaultSizeUpload
              btnText="上传"
              upType="JPG"
              :materialCategoryId="row.id"
              :isTable="true"
              @startProgress="() => {}"
              @endProgress="() => {}"
              btnType="original"
              :loadData="loadData"
              saveUrl="/material/edit"
            />
          </template>
          <template v-else-if="record.dataIndex == 'approveAction'" v-slot="{ row }">
            <a v-if="row.status != 1" style="margin-right:15px" @click.stop="approveFinish([row])">同意</a>
            <a v-if="row.status != 2" @click.stop="openRemark([row])">拒绝</a>
          </template>
          <template v-else-if="record.dataIndex == 'materialDepartments'" v-slot="{ row }">
            <a-tag v-for="one in row.departs" :key="one.id">
              {{ one.name || one.name }}
            </a-tag>
          </template>
          <template v-else-if="record.dataIndex == 'weekAvailableCount'" v-slot="{ row }">{{ row.weekTotal }}</template>
          <template v-else-if="record.dataIndex == 'date'" v-slot="{ row }">{{
            row.date && row.date.slice(0, 7)
          }}</template>
          <template v-else-if="record.dataIndex == 'userFile.weekFreeCount'" v-slot="{ row }">{{
            row.weekCanScheduleCount
          }}</template>
          <template v-else-if="record.dataIndex == 'weekTaskCount'" v-slot="{ row }">{{ row.weekTaskCount }}</template>
          <template v-else-if="record.dataIndex == 'userFile.qq'" v-slot="{ row }">
            <span>
              <div v-if="row.userFile.qq">
                <a-icon type="qq" style="width:1vw;color:hsl(0deg 0% 75%);" />
                <span style="margin-left:3px">{{ row.userFile.qq }}</span>
              </div>
              <div v-if="row.userFile.qqType">
                <a-icon type="wechat" style="width:1vw;color:hsl(0deg 0% 75%);" />
                <span style="margin-left:3px">{{ row.userFile.qqType }}</span>
              </div>
            </span>
          </template>
          <template v-else-if="record.dataIndex == 'userFile.workCity'" v-slot="{ row }">
            <span>
              <div>{{ getWorkCity(row.userFile.workCity) }}</div>
            </span>
          </template>
          <template v-else-if="record.dataIndex == 'userFile.goodAtProductionType'" v-slot="{ row }">
            <span>
              <template v-if="row.userFile.goodAtProductionType">
                <a-tag v-for="one in row.userFile.goodAtProductionType.split(',')" :key="one">
                  {{
                    one == '其他' ? (row.userFile.skilledProductOther ? row.userFile.skilledProductOther : one) : one
                  }}
                </a-tag>
              </template>
            </span>
          </template>
          <template v-else-if="record.dataIndex == 'userFile.badAtProductionType'" v-slot="{ row }">
            <span>
              <template v-if="row.userFile.badAtProductionType">
                <a-tag v-for="one in row.userFile.badAtProductionType.split(',')" :key="one">
                  {{
                    one == '其他'
                      ? row.userFile.unskilledProductOther
                        ? row.userFile.unskilledProductOther
                        : one
                      : one
                  }}
                </a-tag>
              </template>
            </span>
          </template>
          <template v-else-if="record.dataIndex == 'targetType'" v-slot="{ row }">
            <div v-if="isTaskSelect(row.ruleType) && row.ruleType">
              <a-tag v-for="one in row.stages" :key="one.id">
                {{ getStageName(one.stageName, row.ruleType) }}
              </a-tag>
            </div>
            <div v-else-if="row.ruleType == 'LEVEL'">
              <a-tag v-for="one in row.members" :key="one.positionId">
                {{ '职位-' + one.positionName }}
              </a-tag>
            </div>
          </template>
          <template v-else-if="record.dataIndex == 'level'" v-slot="{ row }">
            <a-select v-if="isEdit" v-model="row.level">
              <a-select-option value="专家">专家</a-select-option>
              <a-select-option value="高级">高级</a-select-option>
              <a-select-option value="中级">中级</a-select-option>
              <a-select-option value="低级">低级</a-select-option>
              <a-select-option value="新人">新人</a-select-option>
            </a-select>
            <span v-else>{{ row.level }}</span>
          </template>
          <template v-else-if="record.dataIndex == 'status' && tableType != 'ratingTable'" v-slot="{ row }">
            <div>
              {{ getStatusText(row)
              }}<!-- {{row.statusDesc}} -->
            </div>
          </template>
          <template v-else-if="record.dataIndex == 'status' && tableType == 'ratingTable'" v-slot="{ row }">
            {{ getRatingStatusText(row) }}
          </template>
          <template v-else-if="record.dataIndex == 'ruleType'" v-slot="{ row }">
            <span>{{ getRuleType(row.ruleType) }}</span>
          </template>
          <template v-else-if="record.dataIndex == 'departments'" v-slot="{ row }">
            <a-tag v-for="one in row.departments" :key="one.departId">
              {{ one.departName }}
            </a-tag>
          </template>
          <template v-else-if="record.dataIndex == 'userFile.workDays'" v-slot="{ row }">
            <a-tag
              v-for="one in Array.from(row.userFile.workDays || []).sort((a, b) => {
                return a - b
              })"
              :key="one"
            >
              {{
                one == '1'
                  ? '周一'
                  : one == '2'
                  ? '周二'
                  : one == '3'
                  ? '周三'
                  : one == '4'
                  ? '周四'
                  : one == '5'
                  ? '周五'
                  : one == '6'
                  ? '周六'
                  : one == '7'
                  ? '周日'
                  : one == '-1'
                  ? '跳过中国法定节假日'
                  : ''
              }}
            </a-tag>
          </template>
          <template v-if="record.dataIndex == 'targetType'" #filter="{ $panel, column }">
            <div style="padding-left:10px;padding-right:50px;min-height:30px;max-height:200px;overflow-y:auto">
              <a-tree
                v-model="checkedTargetKeys"
                @select="
                  (selectedKeys, info) => {
                    treeSelect(record.dataIndex, selectedKeys, info, $panel)
                  }
                "
                checkable
                :tree-data="treeTargetData"
              />
            </div>
          </template>
          <template v-else-if="record.dataIndex == 'approveStatus'" #filter="{ $panel, column }">
            <div style="padding-left:10px;padding-right:50px;min-height:30px;max-height:200px;overflow-y:auto">
              <a-tree
                v-model="checkedAppKeys"
                @select="
                  (selectedKeys, info) => {
                    treeAppSelect(record.dataIndex, selectedKeys, info, $panel)
                  }
                "
                checkable
                :tree-data="approveData"
              />
            </div>
          </template>
          <template v-else-if="record.dataIndex == 'applyTime'" #filter="{ $panel, column }">
            <a-spin
              :spinning="filterLoading"
              style="padding-left:10px;padding-right:50px;min-height:30px;max-height:200px;overflow-y:auto"
            >
              <a-tree
                v-model="checkedDeadlineKeys"
                :defaultExpandedKeys="defaultOpenKeys"
                @expand="treeExpand"
                @select="
                  (selectedKeys, info) => {
                    treeSelect(record.dataIndex, selectedKeys, info)
                  }
                "
                checkable
                :tree-data="treeTimeData"
              />
            </a-spin>
          </template>
        </vxe-column>
        <vxe-column width="250" v-if="groupBy.length">
          <template v-slot="{ row }">
            <div style="width:240px" @click.stop v-if="groupBy.length && row.level == groupBy.length - 1">
              <a-pagination
                size="small"
                :showLessItems="true"
                :hideOnSinglePage="true"
                :pageSize="tablePage.pageSize"
                @change="
                  (page, pageSize) => {
                    treeTablePageChange(page, pageSize, row)
                  }
                "
                :default-current="1"
                :total="row.groupCount"
              />
            </div>
          </template>
        </vxe-column>
      </vxe-table>
    </a-dropdown>
  </a-spin>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JVxeTableMixin'
import { getAction } from '@/api/manage'
import { citys } from './modules/city.js'
import { createRuleTypeList } from '@/enums/ruleTypeList'
import defaultSizeUpload from '@/components/default-drag-size-upload'
import { batchStyleInsertHeader, filterObj, handleArrayParams } from '@/utils/util'
import { postAction, putAction } from '../../api/manage.js'
export default {
  props: {
    isPreview: {
      type: Boolean,
      default: false
    },
    isMaterial: {
      type: Boolean,
      default: false
    },
    url: {
      type: Object,
      default: () => {}
    },
    tablePage: {
      type: Object,
      default: () => {}
    },
    columns: {
      type: Array,
      default: () => []
    },
    selectedRowKeysprop: {
      type: Array,
      default: () => []
    },
    selectionRowsProp: {
      type: Array,
      default: () => []
    },
    groupBy: {
      type: Array,
      default: () => []
    },
    queryParamProp: {
      type: Object,
      default: () => {}
    },
    tableType: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    defaultSizeUpload
  },
  mixins: [JeecgListMixin],
  data() {
    return {
      propertys: [],
      tableShow: true,
      filterLoading: true,
      methodName: 'meanNum',
      visible: false,
      color: '',
      copyColumns: [
        {
          dataIndex: ''
        }
      ],
      ruleTypeList: {},
      selectRows: [],
      treeData: [],
      treeTimeData: [],
      checkedKeys: [],
      treeTargetData: [],
      approveData: [
        {
          title: '已通过',
          value: 1,
          key: 1
        },
        {
          title: '未通过',
          value: 2,
          key: 2
        },
        {
          title: '等待中',
          value: 0,
          key: 0
        }
      ],
      username: '',
      orders: [],
      filterList: [],
      checkedTargetKeys: [],
      checkedAppKeys: [],
      checkedDeadlineKeys: [],
      defaultOpenKeys: [],
      treeNodeName: 'productOrder',
      openIdx: 0,
      dataSourceCopy: [],
      fontColor: ''
    }
  },
  computed: {
    backCss() {
      let colorBack = this.color
      let color = this.$store.state.app.color
      let fontColor = this.fontColor
      let height = this.isPreview ? 'calc(100vh - 305px)' : 'calc(100vh - 430px)'
      return {
        '--back-color': colorBack,
        '--theme-color': color,
        '--font-color': fontColor,
        '--table-height': height
      }
    }
  },
  created() {
    this.ruleTypeList = createRuleTypeList()
  },
  mounted() {
    this.copyColumns = JSON.parse(JSON.stringify(this.columns))
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
            localStorage.setItem(
              'color',
              JSON.stringify({ hoverColor: this.color, color: this.$store.state.app.color })
            )
          }
        }
      }
    }, 50)
  },
  watch: {
    checkedKeys(val) {
      console.log('onCheck', val)
    },
    checkedTargetKeys() {
      if (this.checkedTargetKeys.length) {
        Array.from(document.getElementsByClassName('vxe-filter--btn'))[1].className =
          'vxe-filter--btn iconfont icon-shaixuan icon filter-product-btn'
      } else {
        Array.from(document.getElementsByClassName('vxe-filter--btn'))[1].className =
          'vxe-filter--btn iconfont icon-shaixuan icon'
      }
    },
    checkedDeadlineKeys() {
      if (this.checkedDeadlineKeys.length) {
        Array.from(document.getElementsByClassName('vxe-filter--btn'))[0].className =
          'vxe-filter--btn iconfont icon-shaixuan icon filter-product-btn'
      } else {
        Array.from(document.getElementsByClassName('vxe-filter--btn'))[0].className =
          'vxe-filter--btn iconfont icon-shaixuan icon'
      }
    },
    checkedAppKeys() {
      if (this.checkedAppKeys.length) {
        Array.from(document.getElementsByClassName('vxe-filter--btn'))[1].className =
          'vxe-filter--btn iconfont icon-shaixuan icon filter-product-btn'
      } else {
        Array.from(document.getElementsByClassName('vxe-filter--btn'))[1].className =
          'vxe-filter--btn iconfont icon-shaixuan icon'
      }
    }
  },
  methods: {
    async filterVisible(e) {
      let res = null
      if (e.visible) {
        this.filterLoading = true
        switch (e.property) {
          case 'applyTime':
            res = await getAction('/user/leave/groupBy?groupBy=applyTime', {
              status: this.queryParamProp.status.join(','),
              searchType: this.queryParamProp.searchType,
              searchText: this.queryParamProp.searchText
            })
            res.data = res.data.filter(item => item.applyTime)
            let dateArr = res.data.map(item => {
              return (
                item.applyTime.split('-')[0] +
                '-' +
                item.applyTime.split('-')[1] +
                '-' +
                item.applyTime.split(' ')[0].split('-')[2]
              )
            })
            let dateTree = []
            dateArr.forEach(item => {
              if (
                dateTree.every(i => {
                  return i.value != item.split('-')[0]
                })
              ) {
                dateTree.push({
                  title: item.split('-')[0] + '年',
                  value: item.split('-')[0],
                  key: item.split('-')[0],
                  children: []
                })
              }
              dateTree.forEach(i => {
                dateArr.forEach(item => {
                  if (i.value == item.split('-')[0]) {
                    if (
                      i.children.every(d => {
                        return d.value.split('-')[1] != item.split('-')[1]
                      })
                    ) {
                      i.children.push({
                        title: item.split('-')[1][0] != '0' ? item.split('-')[1] + '月' : item.split('-')[1][1] + '月',
                        value: item.split('-')[0] + '-' + item.split('-')[1],
                        key: item.split('-')[0] + '-' + item.split('-')[1],
                        children: []
                      })
                    }
                  }
                })
              })
              dateTree.forEach(i => {
                dateArr.forEach(item => {
                  if (i.value == item.split('-')[0]) {
                    i.children?.forEach(c => {
                      if (c.value.split('-')[1] == item.split('-')[1]) {
                        if (
                          c.children.every(e => {
                            return e.value.split('-')[2] != item.split('-')[2]
                          })
                        ) {
                          c.children.push({
                            title:
                              item.split('-')[2][0] != '0' ? item.split('-')[2] + '日' : item.split('-')[2][1] + '日',
                            value: item,
                            key: item
                          })
                        }
                      }
                    })
                  }
                })
              })
              this.filterLoading = false
              this.treeTimeData = dateTree
            })
            return
        }
      }
    },
    treeSelect(type, selectedKeys, info, $panel) {
      if (type == 'applyTime') {
        this.checkedDeadlineKeys = selectedKeys
      } else {
        this.checkedTargetKeys = selectedKeys
      }
    },
    treeAppSelect(type, selectedKeys, info, $panel) {
      this.checkedAppKeys = selectedKeys
    },
    treeTablePageChange(page, pageSize, row) {
      console.log(page, pageSize, this.queryParamProp)
      console.log(this.dataSource, row)
      var params = this.getQueryParams() //查询条件
      /*       params.current = params.pageNo
      params.size = params.pageSize */
      if (this.tableType == 'ratingTable' || this.tableType == 'reviewTable') {
        params.current = this.tablePage.currentPage
        params.size = this.tablePage.pageSize
      }
      params.current = page
      getAction(this.url.list, params).then(res => {
        console.log('JeecgListMixin loadData getAction over', { res })
        if (res.success) {
          res.data.dataList.forEach(item => {
            item.key = 'row_' + item.id
          })
          //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
          row.children = res.data.dataList
          this.$refs.xTable1.updateData()
          this.$nextTick(() => {
            /*             Array.from(document.getElementsByClassName('vxe-body--row')).forEach(item => {
              if (!item.attributes[0].value) {
                item.attributes[0].value = 'row_' + Math.random()
                item.attributes[0].textContent = item.attributes[0].value
              }
            }) */
          })
        }
      })
    },
    updateData() {
      this.$refs.xTable1.updateData()
    },
    toggleExpandChangeEvent({ row, expanded }) {
      this.$refs.xTable1.toggleTreeExpand(row)
      this.$refs.xTable1.updateData()
      return
    },
    loadChildrenMethod(e) {
      let idx = this.groupBy.indexOf(e.row.id.split('-')[0])
      if (idx < this.groupBy.length - 1) {
        this.openIdx = idx + 1
        this.filters.groupBy = this.groupBy[this.openIdx]
        this.filters['group' + idx + '.' + this.groupBy[idx]] = e.row[this.groupBy[idx]]
        for (let key in this.filters) {
          if (key.indexOf('.') > -1 && key.indexOf('group') > -1) {
            console.log(key.split('.')[0].split('group')[1], idx)
            if (key.split('.')[0].split('group')[1] > idx) {
              delete this.filters[key]
            }
          }
        }
        if (!this.filters.gexpr || this.filters.gexpr.indexOf('group' + idx) == -1) {
          this.filters.gexpr = (this.queryParamProp.gexpr || this.filters.gexpr) + '&' + 'group' + idx
        }
        if (this.filters.gexpr[0] == '&') {
          this.filters.gexpr = this.filters.gexpr.slice(1)
        }
        var params = this.getQueryParams() //查询条件
        /*       params.current = params.pageNo
      params.size = params.pageSize */
        if (this.tableType == 'ratingTable' || this.tableType == 'reviewTable') {
          params.current = this.tablePage.currentPage
          params.size = this.tablePage.pageSize
        }
        if (this.tableType != 'ratingTable' && this.tableType != 'reviewTable') {
          params.size = -1
        }
        return getAction(this.url.list, params).then(res => {
          console.log('JeecgListMixin loadData getAction over', { res })
          if (res.success) {
            //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
            let arr = res.data.records || res.data.dataList || res.data
            arr.forEach((item, index) => {
              item.id = this.groupBy[this.openIdx] + '-' + index
              item.label = item[this.groupBy[this.openIdx]]
              if (this.groupBy[this.openIdx] == 'status') {
                item.label = this.getStatusText({ status: item[this.groupBy[this.openIdx]] })
              } else if (this.groupBy[this.openIdx] == 'type') {
                item.label =
                  item.label == 'TRANSLATOR'
                    ? '翻译'
                    : item.label == 'BUILDER'
                    ? '制作'
                    : item.label == 'NOT_LOCAL'
                    ? '非本地化'
                    : ''
              }
              item.level = this.openIdx
              item.groupCount = item.groupCount - 0
              if (item.groupCount) {
                item.hasChild = true
              } else {
                item.hasChild = false
              }
            })
            return arr
          }
        })
      } else {
        this.openIdx = idx + 1
        this.filters.groupBy = ''
        this.filters['group' + idx + '.' + this.groupBy[idx]] = e.row[this.groupBy[idx]]
        if (!this.filters.gexpr || this.filters.gexpr.indexOf('group' + idx) == -1) {
          this.filters.gexpr = (this.queryParamProp.gexpr || this.filters.gexpr) + '&' + 'group' + idx
        }
        if (this.filters.gexpr[0] == '&') {
          this.filters.gexpr = this.filters.gexpr.slice(1)
        }
        var params = this.getQueryParams() //查询条件
        /*       params.current = params.pageNo
      params.size = params.pageSize */
        if (this.tableType == 'ratingTable' || this.tableType == 'reviewTable') {
          params.current = this.tablePage.currentPage
          params.size = this.tablePage.pageSize
        }
        if (this.tableType != 'ratingTable' && this.tableType != 'reviewTable') {
          params.size = -1
        }
        return getAction(this.url.list, params).then(res => {
          console.log('JeecgListMixin loadData getAction over', { res })
          if (res.success) {
            //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
            let arr = res.data.records || res.data.dataList || res.data
            arr.forEach((item, index) => {
              item.label = item[this.groupBy[this.openIdx]]
              if (item.groupCount) {
                item.hasChild = true
              } else {
                item.hasChild = false
              }
            })
            return arr
          }
        })
      }
    },
    updateFilterEvent() {
      const xTable = this.$refs.xTable1
      this.filterList.forEach(item => {
        const column = xTable.getColumnByField(item.column.field)
        // 修改筛选列表，并默认设置为选中状态
        const values = JSON.parse(JSON.stringify(item.values))
        let arr = []
        values.forEach(i => {
          arr.push({ label: i, value: i, checked: true })
        })
        xTable.setFilter(column, arr)
      })
      xTable.updateData()
    },
    goToProduct(e, record) {
      console.log(record)
      window.open(`/production/manage/detail/${record.productId}?&name=${record.productName}`)
    },
    async customSortMethod({ sortList }) {
      let oldData = JSON.stringify(this.orders)
      this.orders = sortList.map(item => {
        return { column: item.property, asc: item.order }
      })
      let newData = JSON.stringify(this.orders)
      console.log(newData, oldData)
      if (oldData != newData) {
        var params = this.getQueryParams() //查询条件
        params.current = params.pageNo
        params.size = params.pageSize
        params.current = this.tablePage.currentPage
        params.size = this.tablePage.pageSize
        let orderBy = {}
        params.orderBy = ''
        this.orders.forEach(item => {
          orderBy[item.column] = item.asc
        })
        for (let key in orderBy) {
          params.orderBy = params.orderBy + key + ':' + orderBy[key] + ','
        }
        this.filters.orderBy = params.orderBy
        if (this.groupBy.length) {
          this.filters.groupBy = this.groupBy[0]
          for (let key in this.filters) {
            this.groupBy.forEach((item, index) => {
              if ('group' + index + '.' + item == key) {
                delete this.filters[key]
              }
            })
          }
        }
        this.$emit('getTableFilterData')
        /*         const res = await getAction(this.url.list, params)
        if (res.code == 200) {
          this.dataSource = res.data.records || res.data.dataList
          if (this.groupBy.length) {
            this.dataSource.forEach((item, index) => {
              item.id = this.groupBy[0] + '-' + index
              item.label = item[this.groupBy[0]]
              if (this.groupBy[0] == 'status') {
                item.label = this.getStatusText({ status: item[this.groupBy[0]] })
              } else if (this.groupBy[0] == 'type') {
                item.label = item.label == 'TRANSLATOR' ? '翻译' : item.label == 'BUILDER' ? '制作' : ''
              }
              item.level = 0
              item.groupCount = item.groupCount - 0
              if (item.groupCount) {
                item.hasChild = true
              } else {
                item.hasChild = false
              }
            })
          }
          if (this.tableType == 'ratingTable') {
            this.dataSource?.forEach(item => {
              if (item.month) {
                item.month = item.month.split('-')[0] + '-' + item.month.split('-')[1]
              }
            })
          }
        } else {
          this.$message.error('排序失败')
        } */
      }
    },
    treeExpand(keys) {
      this.defaultOpenKeys = keys
    },
    nickNameFilter(e, $panel, column) {
      console.log(this.nickName, $panel, column)
    },
    getRuleType(type) {
      return this.ruleTypeList[type] ? this.ruleTypeList[type].label : ''
    },
    isTaskSelect(ruleType) {
      return ['KPI', 'FACTOR', 'MONEY', 'DIFFICULTLY', 'KPI_SCORE', 'COEFFICIENT', 'NOT_LOCAL'].includes(ruleType)
    },
    getStageName(stageName, ruleType) {
      let rule = this.ruleTypeList[ruleType] ? this.ruleTypeList[ruleType].label : ''
      switch (rule) {
        case '考核规则':
          stageName = '阶段-' + stageName
          break
        case '价格规则':
          stageName = '阶段-' + stageName
          break
        case '等级规则':
          stageName = '阶段-' + stageName
          break
        case '单拎系数规则':
          stageName = '阶段-' + stageName
          break
        case '难度系数规则':
          stageName = '阶段-' + stageName
          break
        case '考核得分系数规则':
          stageName = '阶段-' + stageName
          break
        case '难易程度系数':
          stageName = '阶段-' + stageName
          break
        case '系数规则':
          stageName = '阶段-' + stageName
          break
      }
      return stageName
    },
    cellClickEvent(e) {
      console.log(e._columnIndex)
      if (!e._columnIndex) return
      if (this.isMaterial) {
        e.row.index = e._columnIndex
      }
      if (e.row.groupCount) {
        if (e.row.expend) {
          e.row.expend = false
        } else {
          e.row.expend = true
        }
        this.$refs.xTable1.toggleTreeExpand(e.row)
        this.$refs.xTable1.updateData()
        return
      }
      if (e._columnIndex && !this.isEdit) this.$emit('openModal', e.row)
    },
    selectChangeEvent() {
      if (this.isPreview) {
        this.clearSelectKeys()
      } else {
        const records = this.$refs.xTable1.getCheckboxRecords()
        this.$emit('setRowkeys', records)
        this.selectRows = records
        this.$refs.xTable1.updateFooter() //手动更新表尾
      }
    },
    applyTimeChange(e) {
      console.log(e)
    },
    updateStatus(value) {
      this.$refs.xTable1.hideColumn(value)
      this.$refs.xTable1.showColumn(value)
    },
    hiddenColumn(value) {
      this.$refs.xTable1.hideColumn(value)
    },
    showColumn(value) {
      this.$refs.xTable1.showColumn(value)
    },
    clearSelectKeys() {
      this.$emit('setRowkeys', [])
      this.$refs.xTable1.clearCheckboxRow()
      this.selectRows = []
      this.$refs.xTable1.updateFooter() //手动更新表尾
    },
    checkChange(menuIndex) {
      this.copyColumns = this.columns.filter(item => {
        return item.checked
      })
      Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
        .filter((item, index) => {
          return index != 0
        })
        .forEach((item, i) => {
          try {
            if (i == menuIndex) {
              if (this.columns[i].checked) {
                item.style.display = 'table-cell'
              } else {
                item.style.display = 'none'
              }
            }
          } catch (err) {}
        })
    },
    compute(methodName, index, menuIndex, menuText) {
      /* this.property='weekTaskCount' */
      this.methodName = methodName
      if (
        !this.propertys.some(item => {
          return item == index
        })
      ) {
        this.propertys.push(index)
      }
      this.$nextTick(() => {
        this.$refs.xTable1.updateFooter() //手动更新表尾
        setTimeout(() => {
          this.$refs.xTable1.setCheckboxRow(this.selectRows, true)
          this.getFilters()
          Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
            .filter((item, index) => {
              return index != 0
            })
            .forEach((item, i) => {
              try {
                if (i == menuIndex) {
                  /* item.childNodes[0].childNodes[0].innerText */
                  this.$refs['statistics-' + i][0].$el.innerText = menuText + ':' + this[methodName]([], index)
                  /* this.$refs['statistics-' + i][0].$el.className = 'statistics-show' */
                }
                if (this.$refs['statistics-' + i][0].$el.innerText != '统计') {
                  item.childNodes[0].childNodes[0].innerText = ''
                  item.childNodes[0].childNodes[0].style.opacity = '1'
                }
                item.childNodes[0].childNodes[0].appendChild(this.$refs['statistics-' + i][0].$el)
              } catch (err) {}
            })
        }, 0)
      })
    },
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (this.propertys.includes(column.property)) {
            return parseInt(this[this.methodName](data, column.property))
          }
          if (columnIndex == 0) {
            return '已选择：' + this.selectRows.filter(s => !s.children).length + '项'
          }
          return ''
        })
      ]
    },
    meanNum(list, field) {
      list = this.selectRows
      if (field == 'weekAvailableCount') {
        let count = 0
        list.forEach(item => {
          let weekAvailableCount = item.userFile.workDays
            ? item.userFile.workDays.length * item.userFile.workdayAvailableCount
            : 0
          count += Number(weekAvailableCount)
        })
        return count || 0
      }
      if (field.split('.')[0] != 'userFile') {
        let count = 0
        list.forEach(item => {
          count += Number(item[field])
        })
        return count || 0
      } else {
        let count = 0
        list.forEach(item => {
          count += Number(item.userFile[field.split('.')[1]])
        })
        return count || 0
      }
    },
    allNum(list, field) {
      return list.length
    },
    sumNum(list, field) {
      list = this.selectRows
      if (!list.length) return 0
      if (field == 'weekAvailableCount') {
        let count = 0
        list.forEach(item => {
          let weekAvailableCount = item.userFile.workDays
            ? item.userFile.workDays.length * item.userFile.workdayAvailableCount
            : 0
          count += Number(weekAvailableCount)
        })
        let num = count / list.length
        return num.toFixed(2) || 0
      }
      if (field.split('.')[0] != 'userFile') {
        let count = 0
        list.forEach(item => {
          count += Number(item[field])
        })
        let num = count / list.length
        return num.toFixed(2) || 0
      } else {
        let count = 0
        list.forEach(item => {
          count += Number(item.userFile[field.split('.')[1]])
        })
        let num = count / list.length
        return num.toFixed(2) || 0
      }
    },
    filterMethod(index, value, row, column) {
      this.$refs.xTable1.removeCheckboxRow()
      this.clearSelectKeys()
      switch (index) {
        case 'nickName':
          return row.nickName == value
        case 'name':
          return row.name == value
        case 'weekAvailableCount':
          if (value == '1') {
            return row.workDays
          } else {
            return !row.workDays
          }
        case 'weekTaskCount':
          if (value == '1') {
            return row.weekTaskCount
          } else {
            return !row.weekTaskCount
          }
        case 'userFile.weekFreeCount':
          if (value == '1') {
            return row.userFile.weekFreeCount !== false
          } else {
            return row.userFile.weekFreeCount === false
          }
        case 'departs':
          return row.departs.some(item => {
            return item.departName == value
          })
        case 'positions':
          return row.positions.some(item => {
            return item.name == value || item.positionName == value
          })
        case 'materialPositions':
          return row.positions.some(item => {
            return item.name == value
          })
        /* case 'platform':
          return row.platform.platformName == value */
        case 'product.productionName':
          return row.product.productionName == value
        case 'type':
          return row.type == value
        case 'creator':
          return row.creator.userName == value
        case 'product.sequenceNo':
          return row.product.sequenceNo == value
        case 'departments':
          return row.departments.some(item => {
            return item.departName == value
          })
        case 'materialType':
          return (row.materialType.name = value)
        case 'materialDepartments':
          return row.departs.some(item => {
            return item.name == value
          })
        case 'ruleType':
          return row.ruleType == value
        case 'value':
          return row.value == value
        case 'status':
          return row.status == value || value == '0'
        case 'date':
          return row.date == value
        case 'targetType':
          return value == '2' ? row.ruleType != 'LEVEL' : row.ruleType == 'LEVEL'
        case 'userFile.qq':
          return row.userFile[value]
        case 'userFile.goodAtProductionType':
          if (row.userFile.goodAtProductionType) {
            return (
              row.userFile.goodAtProductionType.split(',').some(item => {
                return item == value
              }) || row.userFile.skilledProductOther == value
            )
          }
        case 'userFile.badAtProductionType':
          if (row.userFile.badAtProductionType) {
            return (
              row.userFile.badAtProductionType.split(',').some(item => {
                return item == value
              }) || row.userFile.unskilledProductOther == value
            )
          }
        case 'userFile.workDays':
          if (row.userFile.workDays) {
            return row.userFile.workDays.some(item => {
              return item == value
            })
          }
        case 'userFile.contractType':
          return row.userFile.contractType == value
        case 'userFile.workCity':
          if (row.userFile.workCity) {
            return row.userFile.workCity.split(',').some(item => {
              return item == value
            })
          }
      }
    },
    async approveFinish(rows) {
      let that = this
      this.$confirm({
        title: '确认通过?',
        okText: '确认',
        cancelText: '取消',
        zIndex: 9999,
        async onOk() {
          rows.forEach(row => {
            row.status = 1
          })
          const res = await putAction('/user/leave/edit', rows)
          if (res.code == 200) {
            that.$message.success('已同意')
            rows.forEach(async r => {
              await postAction('/workSchedule/', {
                startDate: r.applyStartTimeStr,
                endDate: r.applyEndTimeStr,
                type: r.type,
                userIds: [r.applyUserId]
              })
            })
            that.loadData()
            that.selectedRowKeys = []
            that.$emit('reUserInfo')
          } else {
            that.$message.error(res.msg)
          }
        },
        onCancel() {
          console.log('Cancel')
        }
      })
      /*       this.$confirm({
        title: '确认删除?',
        okText: '确认',
        cancelText: '取消',
        zIndex: 9999,
        async onOk() {
          rows.forEach(row=>{
            row.status=1
          })
          const res = await putAction('/taskSchedule/changeApply/confirm',rows)
          if (res.code == 200) {
            that.$message.success('已同意')
            that.loadData()
            that.selectedRowKeys = []
          } else {
            that.$message.error(res.msg)
          }
        },
        onCancel() {
          console.log('Cancel')
        }
      }) */
    },
    openRemark(rows) {
      this.$emit('openRemark', rows)
    },
    async approveReject(rows, remark) {
      let that = this
      rows.forEach(row => {
        row.status = 2
        row.remark = remark
      })
      const res = await putAction('/user/leave/edit', rows)
      if (res.code == 200) {
        that.$message.success('已拒绝')
        rows.forEach(async r => {
          await postAction('/workSchedule/', {
            startDate: r.applyStartTimeStr,
            endDate: r.applyEndTimeStr,
            type: null,
            userIds: [r.applyUserId]
          })
        })
        that.loadData()
        that.selectedRowKeys = []
        that.$emit('closeRemark')
      } else {
        that.$message.error(res.msg)
      }
    },
    async filterChangeEvent({ column, $event, values, filters }) {
      console.log(column, filters)
      this.filterList = filters
      console.log(`${column.property} 筛选了数据`)
      if ($event.target.innerText == '筛选') {
        /*         if (this.tableType == 'ratingTable' && column.property == 'month') {
          console.log(this.checkedKeys)
          let arr = JSON.parse(JSON.stringify(this.checkedKeys.filter(item => item.length > 4))).map(
            item => item + '-01'
          )
        } else if (this.tableType == 'reviewTable') {
          let params = {}
          console.log(filters)
          filters.forEach(item => {
            if (item.property == 'product.productionName') {
              if (params.products) {
                params.products = [...params.products, ...item.values.map(productionName => ({ productionName }))]
              } else {
                params.products = item.values.map(productionName => ({ productionName }))
              }
            } else if (item.property == 'platform') {
              params.platforms = item.values.map(platformName => ({ platformName }))
            } else if (item.property == 'product.sequenceNo') {
              if (params.products) {
                params.products = [...params.products, ...item.values.map(sequenceNo => ({ sequenceNo }))]
              } else {
                params.products = item.values.map(sequenceNo => ({ sequenceNo }))
              }
            } else if (item.property == 'type') {
              params.types = item.values
            } else if (item.property == 'value') {
              params.values = item.values
            } else if (item.property == 'creator') {
              params.creators = item.values.map(userName => ({ userName }))
            } else if (item.property == 'status') {
              params.statusList = item.values
            }
          })
          var queryParams = this.getQueryParams()
          if (this.tableType == 'ratingTable' || this.tableType == 'reviewTable') {
            queryParams.current = this.tablePage.currentPage
            queryParams.size = this.tablePage.pageSize
          }
          const res = await getAction(this.url.list + '?' + new URLSearchParams(handleArrayParams(params)), queryParams)
          if (res.code == 200) {
            this.dataSource = res.data.records
          } else {
            this.$message.error('筛选失败')
          }
        } */
        if (column.property == 'approveStatus') {
          this.$emit('changeStarus', this.checkedAppKeys)
          return
        }
        if (column.property == 'applyTime') {
          this.$emit('changeTime', this.checkedDeadlineKeys)
          return
        }
        if (column.property == 'targetType') {
          let arr = []
          this.checkedTargetKeys.forEach(item => {
            this.dataSourceCopy.forEach(data => {
              let text = ''
              if (this.isTaskSelect(data.ruleType)) {
                data.stages?.forEach(i => {
                  text = this.getStageName(i.stageName, data.ruleType)
                  if (text == item) {
                    arr.push(data)
                  }
                })
              } else if (data.ruleType == 'LEVEL') {
                data.members?.forEach(i => {
                  text = '职位-' + i.positionName
                  if (text == item) {
                    arr.push(data)
                  }
                })
              }
            })
          })
          arr = Array.from(new Set(arr))
          if (this.checkedTargetKeys.length) {
            this.dataSource = arr
          }
          return
        }
        let arr = []
        /* filters.forEach(item => {
          if (item.property == 'ruleType') {
            this.dataSource.forEach(i=>{
              if(item.values.some(v=>v==i.ruleType)){
                if(arr.every(a=>a.id!=i.id)) arr.push(i)
              }
            })
          } else if (item.property == 'departments') {
            this.dataSource.forEach(i=>{
              if(item.values.some(v=>{return i.departments.some(d=>d.departName==v)})){
                if(arr.every(a=>a.id!=i.id)) arr.push(i)
              }
            })
          } else if (item.property == 'positions') {
            this.dataSource.forEach(i=>{
              if(item.values.some(v=>{return i.positions.some(d=>d.positionName==v)})){
                if(arr.every(a=>a.id!=i.id)) arr.push(i)
              }
            })
          }
        }) */
        if (filters.length && filters[filters.length - 1].property == 'ruleType') {
          this.dataSourceCopy.forEach(i => {
            if (filters[filters.length - 1].values.some(v => v == i.ruleType)) {
              if (arr.every(a => a.id != i.id)) arr.push(i)
            }
          })
        } else if (filters.length && filters[filters.length - 1].property == 'departments') {
          this.dataSourceCopy.forEach(i => {
            if (
              filters[filters.length - 1].values.some(v => {
                return i.departments?.some(d => d.departName == v)
              })
            ) {
              if (arr.every(a => a.id != i.id)) arr.push(i)
            }
          })
        } else if (filters.length && filters[filters.length - 1].property == 'positions') {
          this.dataSourceCopy.forEach(i => {
            if (
              filters[filters.length - 1].values.some(v => {
                return i.positions.some(d => d.positionName == v)
              })
            ) {
              if (arr.every(a => a.id != i.id)) arr.push(i)
            }
          })
        } else if (filters.length && filters[filters.length - 1].property == 'materialPositions') {
          this.dataSource.forEach(i => {
            if (
              filters[filters.length - 1].values.some(v => {
                return i.positions.some(d => d.name == v)
              })
            ) {
              if (arr.every(a => a.id != i.id)) arr.push(i)
            }
          })
        } else if (filters.length && filters[filters.length - 1].property == 'materialDepartments') {
          this.dataSource.forEach(i => {
            if (
              filters[filters.length - 1].values.some(v => {
                return i.departs.some(d => d.name == v)
              })
            ) {
              if (arr.every(a => a.id != i.id)) arr.push(i)
            }
          })
        } else if (filters.length && filters[filters.length - 1].property == 'materialType') {
          this.dataSource.forEach(i => {
            if (
              filters[filters.length - 1].values.some(v => {
                return i.materialType.id == v
              })
            ) {
              if (arr.every(a => a.id != i.id)) arr.push(i)
            }
          })
        }

        this.dataSource = arr
      } else {
        if (column.property == 'targetType') {
          this.checkedTargetKeys = []
        }
        if (column.property == 'approveStatus') {
          this.checkedAppKeys = []
          this.$emit('changeStarus', [])
        }
        if (column.property == 'applyTime') {
          this.checkedDeadlineKeys = []
          this.$emit('changeTime', [])
        }
        if (this.tableType == 'ratingTable' && column.property == 'month') {
          this.checkedKeys = []
        } else if (this.tableType == 'ratingTable' && column.property == 'nickName') {
          this.username = ''
        } else {
          this.loadData()
        }
      }
    },
    getRatingStatusText(record) {
      switch (record.status) {
        case 1:
          return 'HR确认' + (record.rejectType == 1 ? '(BOSS驳回)' : record.rejectType == 2 ? '(人员驳回)' : '')
        case 2:
          return 'BOSS确认' + (record.rejectType == 1 ? '(BOSS驳回)' : record.rejectType == 2 ? '(人员驳回)' : '')
        case 3:
          return '人员确认' + (record.rejectType == 1 ? '(BOSS驳回)' : record.rejectType == 2 ? '(人员驳回)' : '')
        case 4:
          return '人员已确认'
      }
    },
    getStatusText(record) {
      switch (record.status) {
        case 'REVIEW_FINISH':
          return 'BOSS已确认'
        case 'REVIEW_CONFIRM':
          return 'HR已确认'
        case 'REVIEW_REJECT':
          return 'BOSS已驳回'
        case 'REVIEW_SUBMIT':
          return '审核岗已确认'
        case 'REVIEW_SUBMIT_AUTO':
          return '自动推送'
        case 'PREPARE_REJECT_HR':
          return 'HR已驳回'
        case 'PREPARE_REJECT':
          return '校对/监制驳回'
        case 'PREPARE_CONFIRM':
          return '普通岗已确认'
      }
    },
    getQueryParams() {
      //获取查询条件
      let sqp = {}
      if (this.superQueryParams) {
        sqp['superQueryParams'] = encodeURI(this.superQueryParams)
        sqp['superQueryMatchType'] = this.superQueryMatchType
      }
      if (!this.filters.gexpr && this.tableType == 'reviewTable') {
        this.filters.gexpr = this.queryParamProp.gexpr
      }
      var param = Object.assign(sqp, this.queryParamProp, this.isorter, this.filters)
      /*       param.field = this.getQueryField()
      param.pageNo = -1
      param.pageSize = -1 */
      return filterObj(param)
    },
    loadData(arg, id, callback) {
      this.checkedTargetKeys = []
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      var params = this.getQueryParams() //查询条件
      if (this.id) params.id = this.id
      if (id) params.id = id
      this.loading = true
      /*       params.current = params.pageNo
      params.size = params.pageSize */
      if (this.tableType == 'ratingTable' || this.tableType == 'reviewTable') {
        params.current = this.tablePage.currentPage
        params.size = this.tablePage.pageSize
      }
      if (this.tableType != 'ratingTable' && this.tableType != 'reviewTable') {
        params.size = -1
        this.selectedRowKeys = []
        this.selectionRows = []
        this.selectRows = []
      }
      if (this.tableType == 'approveTable') {
        params.status = params.status.join(',')
        params.searchTime = params.searchTime.join(',')
      }
      getAction(this.url.list, params).then(res => {
        console.log('JeecgListMixin loadData getAction over', { res })
        if (res.success) {
          //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
          this.dataSource = res.data.records || res.data.dataList || res.data
          if (this.tableType == 'approveTable') {
            this.dataSource.forEach((data, index) => {
              data.approveSort = index + 1
            })
          }
          if (this.groupBy.length) {
            this.dataSource.forEach((item, index) => {
              item.id = this.groupBy[0] + '-' + index
              item.label = item[this.groupBy[0]]
              if (this.groupBy[0] == 'status') {
                item.label = this.getStatusText({ status: item[this.groupBy[0]] })
              } else if (this.groupBy[0] == 'type') {
                item.label =
                  item.label == 'TRANSLATOR'
                    ? '翻译'
                    : item.label == 'BUILDER'
                    ? '制作'
                    : item.label == 'NOT_LOCAL'
                    ? '非本地化'
                    : ''
              }
              item.level = 0
              item.groupCount = item.groupCount - 0
              if (item.groupCount) {
                item.hasChild = true
              } else {
                item.hasChild = false
              }
            })
          }
          if (this.tableType == 'ratingTable') {
            this.dataSource.forEach(item => {
              item.month = item.month.split('-')[0] + '-' + item.month.split('-')[1]
            })
          }
          if (this.tableType == 'ratingTable' || this.tableType == 'reviewTable') {
            this.$emit('setTablePage', res.data.total || res.data.totalCount)
          } else {
            let dataTree = [
              {
                value: '职位',
                title: '职位',
                key: '职位',
                children: []
              },
              {
                value: '阶段',
                title: '阶段',
                key: '阶段',
                children: []
              }
            ]
            let arr = []
            this.dataSource.forEach(item => {
              item.stages?.forEach(i => {
                if (
                  arr.every(a => a != this.getStageName(i.stageName, item.ruleType)) &&
                  this.isTaskSelect(item.ruleType)
                ) {
                  arr.push(this.getStageName(i.stageName, item.ruleType))
                }
              })
              item.members?.forEach(i => {
                if (arr.every(a => a != this.getStageName(i.stageName, item.ruleType)) && item.ruleType == 'LEVEL') {
                  arr.push('职位-' + i.positionName)
                }
              })
            })
            arr.forEach(a => {
              dataTree.forEach(item => {
                if (item.title == a.split('-')[0]) {
                  if (item.children.every(i => i.key != a)) {
                    item.children.push({
                      value: a.split('-')[1],
                      title: a.split('-')[1],
                      key: a
                    })
                  }
                }
              })
            })
            this.treeTargetData = dataTree
          }
          this.dataSourceCopy = JSON.parse(JSON.stringify(this.dataSource))
          this.getFilters()
          if (
            this.columns.some(item => {
              return item.isNum
            })
          ) {
            Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
              .filter((item, index) => {
                return index != 0
              })
              .forEach((item, index) => {
                if (item.childNodes[0]) {
                  item.childNodes[0].childNodes[0].appendChild(this.$refs['statistics-' + index][0].$el)
                }
              })
          }
          //update-end---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------

          // 字体预览需要往head里插入css样式
          if (this.isInserstCss) {
            batchStyleInsertHeader(this.dataSource)
          }
          this.$refs.xTable1.oncontextmenu = function(e) {
            return false
          }
          this.$emit('setRowkeys', [])
          this.$refs.xTable1.clearCheckboxRow()
          this.$emit('loadData', this.dataSource)
        }
        if (res.code === 510) {
          this.$message.warning(res.message)
        }
        this.loading = false
      })
    },
    goToMaterialList(record) {
      this.$router.push({
        path: `/memberNotice/materialList/?&name=${record.materialType.name}-${record.name}&id=${record.id}`
      })
    },
    async getDateTreeData() {
      const res = await getAction('/rating/condition?property=month')
      if (res.code == 200) {
        let dateArr = res.data.map(item => {
          return item.split('-')[0] + '-' + item.split('-')[1]
        })
        let dateTree = []
        dateArr.forEach(item => {
          if (
            dateTree.every(i => {
              i.value != item.split('-')[0]
            })
          ) {
            dateTree.push({
              title: item.split('-')[0] + '年',
              value: item.split('-')[0],
              key: item.split('-')[0],
              children: []
            })
          }
          dateTree.forEach(i => {
            dateArr.forEach(item => {
              if (i.value == item.split('-')[0]) {
                if (
                  i.children.every(d => {
                    return d.value.split('-')[1] != item.split('-')[1]
                  })
                ) {
                  i.children.push({
                    title: item.split('-')[1][0] != '0' ? item.split('-')[1] + '月' : item.split('-')[1][1] + '月',
                    value: item,
                    key: item
                  })
                }
              }
            })
          })
          this.treeData = dateTree
        })
      } else {
        this.$message.error('未获取月份筛选数据')
      }
    },
    //获取筛选条件
    async getFilters() {
      const $table = this.$refs.xTable1
      if ($table) {
        for (let key in this.dataSource[0]) {
          if (key != 'userFile') {
            const nameColumn = $table.getColumnByField(key)
            if (nameColumn || key == 'positions' || key == 'departs') {
              let arr = []
              switch (key) {
                /* case 'nickName':
                  this.dataSource.forEach(item => {
                    if (
                      arr.every(i => {
                        return i.value != item[key]
                      })
                    ) {
                      arr.push({ label: item[key], value: item[key] })
                    }
                  })
                  break */
                case 'month':
                  this.getDateTreeData()
                  break
                case 'name':
                  arr = this.dataSource.map(item => {
                    return { label: item[key], value: item[key] }
                  })
                  break
                case 'type':
                  const typeRes = await getAction('/difficultyFactor/condition?property=type')
                  arr = typeRes.data.map(item => {
                    return {
                      label:
                        item == 'TRANSLATOR'
                          ? '翻译'
                          : item == 'BUILDER'
                          ? '制作'
                          : item.label == 'NOT_LOCAL'
                          ? '非本地化'
                          : '',
                      value: item
                    }
                  })
                  break
                case 'value':
                  const valueRes = await getAction('/difficultyFactor/condition?property=value')
                  arr = valueRes.data.map(item => {
                    return { label: item, value: item }
                  })
                  break
                case 'date':
                  this.dataSource.forEach(item => {
                    if (
                      arr.every(i => {
                        return i.value != item[key]
                      })
                    ) {
                      arr.push({
                        label: item[key],
                        value: item[key]
                      })
                    }
                  })
                  break
                case 'materialType':
                  this.dataSource.forEach(item => {
                    if (
                      arr.every(i => {
                        return i.value != item[key].id
                      })
                    ) {
                      arr.push({
                        label: item[key].name,
                        value: item[key].id
                      })
                    }
                  })
                  break
                case 'departs':
                  this.dataSource.forEach(item => {
                    item.departs?.forEach(dep => {
                      if (
                        arr.every(i => {
                          return i.label != dep.name && i.label != dep.departName
                        })
                      ) {
                        arr.push({ label: dep.departName || dep.name, value: dep.departName || dep.name })
                      }
                    })
                  })
                  break
                case 'status':
                  if (this.tableType == 'ratingTable') {
                    this.dataSource.forEach(item => {
                      if (
                        arr.every(i => {
                          return i.value != item[key]
                        }) &&
                        this.getRatingStatusText(item)
                      ) {
                        arr.push({
                          label: this.getRatingStatusText(item),
                          value: item[key]
                        })
                      }
                    })
                  } else {
                    const statusRes = await getAction('/difficultyFactor/condition?property=status')
                    arr = statusRes.data.map(item => {
                      return { label: this.getStatusText({ status: item }), value: item }
                    })
                  }
                  break
                case 'creator':
                  const creatorRes = await getAction('/difficultyFactor/condition?property=creator.userName')
                  arr = creatorRes.data.map(item => {
                    return { label: item, value: item }
                  })
                  break
                case 'platform':
                  const res = await getAction('/difficultyFactor/condition?property=platform.platformName')
                  arr = res.data.map(item => {
                    return { label: item, value: item }
                  })
                  break
                case 'weekTaskCount':
                  arr = [
                    { label: '已填写', value: '1' },
                    { label: '未填写', value: '2' }
                  ]
                  break
                case 'ruleType':
                  for (let key in this.ruleTypeList) {
                    arr.push({ label: this.ruleTypeList[key].label, value: this.ruleTypeList[key].value })
                  }
                  break
                case 'positions':
                  this.dataSource.forEach(item => {
                    item.positions?.forEach(position => {
                      if (
                        arr.every(i => {
                          return i.label != position.name && i.label != position.positionName
                        })
                      ) {
                        arr.push({
                          label: position.name || position.positionName,
                          value: position.name || position.positionName
                        })
                      }
                    })
                  })
                  break
                case 'materialPositions':
                  this.dataSource.forEach(item => {
                    item.positions?.forEach(position => {
                      if (
                        arr.every(i => {
                          return i.label != position.name
                        })
                      ) {
                        arr.push({
                          label: position.name,
                          value: position.name
                        })
                      }
                    })
                  })
                  break
                case 'departments':
                  this.dataSource?.forEach(item => {
                    item.departments?.forEach(one => {
                      if (
                        arr.every(i => {
                          return i.label != one.departName
                        })
                      ) {
                        arr.push({ label: one.departName, value: one.departName })
                      }
                    })
                  })
                  break
              }
              $table.setFilter(nameColumn, arr)
              if (key == 'departs' && !nameColumn) {
                $table.setFilter($table.getColumnByField('materialDepartments'), arr)
              }
              if (key == 'positions' && !nameColumn) {
                $table.setFilter($table.getColumnByField('materialPositions'), arr)
              }
            }
          } else {
            for (let userKey in this.dataSource[0].userFile) {
              const nameColumn = $table.getColumnByField(key + '.' + userKey)
              if (nameColumn) {
                let arr = []
                switch (userKey) {
                  case 'qq':
                    arr = [
                      { value: 'qq', label: 'QQ' },
                      { value: 'qqType', label: '微信' }
                    ]
                    break
                  case 'weekFreeCount':
                    arr = [
                      { label: '已填写', value: '1' },
                      { label: '未填写', value: '2' }
                    ]
                    break
                  case 'contractType':
                    this.dataSource.forEach(item => {
                      if (
                        arr.every(i => {
                          return i.label != item.userFile.contractType
                        }) &&
                        item.userFile.contractType
                      ) {
                        arr.push({ label: item.userFile.contractType, value: item.userFile.contractType })
                      }
                    })
                    break
                  case 'workCity':
                    this.dataSource.forEach(data => {
                      if (data.userFile.workCity) {
                        data.userFile.workCity.split(',').forEach(type => {
                          if (
                            arr.every(
                              a =>
                                a.value !=
                                data.userFile.workCity.split(',')[data.userFile.workCity.split(',').length - 1]
                            ) &&
                            type
                          ) {
                            let text = ''
                            let value = ''
                            let workCity = data.userFile.workCity.split(',')
                            citys.options.forEach(item => {
                              if (item.value == workCity[workCity.length - 1]) {
                                text = item.label
                                value = item.value
                              }
                              item.children?.forEach(i => {
                                if (i.value == workCity[workCity.length - 1]) {
                                  text = i.label
                                  value = i.value
                                }
                                i.children?.forEach(e => {
                                  if (e.value == workCity[workCity.length - 1]) {
                                    text = e.label
                                    value = e.value
                                  }
                                })
                              })
                            })
                            if (text) {
                              arr.push({ label: text, value })
                            }
                          }
                        })
                      }
                    })
                    break
                  case 'goodAtProductionType':
                    this.dataSource.forEach(data => {
                      if (data.userFile.goodAtProductionType) {
                        data.userFile.goodAtProductionType.split(',').forEach(type => {
                          if (arr.every(a => a.label != type) && type != '其他') {
                            arr.push({ label: type, value: type })
                          }
                        })
                      }
                      if (
                        data.userFile.skilledProductOther &&
                        arr.every(a => a.label != data.userFile.skilledProductOther)
                      ) {
                        arr.push({
                          label: data.userFile.skilledProductOther,
                          value: data.userFile.skilledProductOther
                        })
                      }
                    })
                    break
                  case 'badAtProductionType':
                    this.dataSource.forEach(data => {
                      if (data.userFile.badAtProductionType) {
                        data.userFile.badAtProductionType.split(',').forEach(type => {
                          if (arr.every(a => a.label != type) && type != '其他') {
                            arr.push({ label: type, value: type })
                          }
                        })
                      }
                      if (
                        data.userFile.unskilledProductOther &&
                        arr.every(a => a.label != data.userFile.unskilledProductOther)
                      ) {
                        arr.push({
                          label: data.userFile.unskilledProductOther,
                          value: data.userFile.unskilledProductOther
                        })
                      }
                    })
                    break
                  case 'workDays':
                    this.dataSource.forEach(data => {
                      if (data.userFile.workDays) {
                        data.userFile.workDays.forEach(one => {
                          if (arr.every(a => a.value != one)) {
                            arr.push({
                              label:
                                one == '1'
                                  ? '周一'
                                  : one == '2'
                                  ? '周二'
                                  : one == '3'
                                  ? '周三'
                                  : one == '4'
                                  ? '周四'
                                  : one == '5'
                                  ? '周五'
                                  : one == '6'
                                  ? '周六'
                                  : one == '7'
                                  ? '周日'
                                  : '',
                              value: one
                            })
                          }
                        })
                      }
                    })
                    break
                }
                $table.setFilter(nameColumn, arr)
              }
            }
          }
          if (key == 'product') {
            for (let userKey in this.dataSource[0].product) {
              const nameColumn = $table.getColumnByField(key + '.' + userKey)
              if (nameColumn) {
                let arr = []
                switch (userKey) {
                  case 'sequenceNo':
                    const res = await getAction('/difficultyFactor/condition?property=product.sequenceNo')
                    arr = res.data.map(item => {
                      return { label: item, value: item }
                    })
                    break
                  case 'productionName':
                    const rest = await getAction('/difficultyFactor/condition?property=product.productionName')
                    arr = rest.data.map(item => {
                      return { label: item, value: item }
                    })
                    break
                }
                $table.setFilter(nameColumn, arr)
              }
            }
          }
        }
      }
    },
    getWorkCity(data) {
      let text = ''
      if (data) {
        data = data.split(',')
        citys.options.forEach(item => {
          if (item.value == data[data.length - 1]) {
            text = item.label
            return text
          }
          item.children?.forEach(i => {
            if (i.value == data[data.length - 1]) {
              text = i.label
              return text
            }
            i.children?.forEach(e => {
              if (e.value == data[data.length - 1]) {
                text = i.label + '/' + e.label
                return text
              }
            })
          })
        })
      }
      return text
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .is--sortable {
  cursor: pointer;
}
/deep/ .vxe-table .vxe-sort--asc-btn {
  left: 0.35em !important;
  color: rgba(0, 0, 0, 0.65) !important;
  /* top:-0.45em !important; */
  top: 0 !important;
}
/deep/ .vxe-table .vxe-sort--desc-btn {
  left: -0.15em !important;
  color: rgba(0, 0, 0, 0.65) !important;
  /* top:-0.45em !important; */
  top: 0 !important;
}
/deep/ .iconfont {
  font-weight: 700 !important;
  font-size: 12px !important;
}
/deep/ .icon-desc {
  display: none !important;
}
.tree-page {
  position: absolute;
  width: 190px;
  left: -202px;
  top: 12px;
}
/deep/ .vxe-table--body-wrapper {
  max-height: var(--table-height) !important;
}
/* /deep/ .ant-spin-container {
  height: calc(100vh - 211px) !important;
} */

.review-product:hover {
  color: var(--theme-color);
  cursor: pointer;
}
.statistics {
  cursor: pointer;
  width: 100%;
  margin-left: -12px;
  &:hover {
    background: var(--back-color);
  }
}
.icon-list {
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
  position: absolute;
  right: 0;
  top: 12px;
  i {
    &:hover {
      cursor: pointer;
    }
  }
}
.statistics-show {
  cursor: pointer;
  width: 100%;
  margin-left: -12px;
}
.statistics-menu-list {
  opacity: 0;
  height: 0;
}
/deep/ .vxe-footer--row {
  .vxe-cell--item {
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: start;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  .vxe-footer--column {
    &:first-child {
      .vxe-cell--item {
        opacity: 1;
        width: 120px;
      }
    }
  }
}
/deep/ .vxe-cell--sort {
  font-size: 12px !important;
  width: 1em !important;
  height: 1.35em !important;
  margin-right: 7px;
}
/deep/ .vxe-cell--filter {
  font-size: 12px !important;
  margin-top: 2px;
}
/deep/ .vxe-header--row {
  .vxe-cell {
    display: flex;
    align-items: center;
    .vxe-cell--title {
      flex: 1;
    }
  }
}
.status-img {
  display: inline-block;
  position: relative;
  margin-right: 8px;
  .status {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }
}
/* /deep/ .vxe-checkbox--icon::before {
  border: 1px solid #dcdfe6 !important;
  
} */
/* /deep/ .is--checked .vxe-checkbox--icon::before{
   background-color:var(--theme-color) !important;
} */
/deep/ .is--indeterminate .vxe-checkbox--icon::before {
  background-color: #fff !important;
}
/deep/ .vxe-checkbox--icon:hover {
  &::before {
    border-color: var(--theme-color) !important;
  }
}
/deep/ .is--checked .vxe-icon-checkbox-checked {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--render-default .vxe-cell--checkbox:not(.is--disabled):hover .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--render-default .vxe-cell--checkbox .vxe-checkbox--icon {
  font-weight: 400 !important;
}
/deep/ .vxe-table--render-default .vxe-body--row.row--current {
  background-color: var(--back-color) !important;
}
/deep/ .vxe-table--render-default .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--border-line {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-top-width: 0 !important;
}
/deep/ .is--filter-active .vxe-cell--filter .vxe-filter--btn {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table .vxe-sort--desc-btn.sort--active {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table .vxe-sort--asc-btn.sort--active {
  color: var(--theme-color) !important;
}
/deep/ .is--checked {
  color: var(--theme-color) !important;
}
/deep/ .is--indeterminate.vxe-table--filter-option .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-option:not(.is--disabled):hover .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-wrapper .vxe-table--filter-footer button:hover {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-wrapper .vxe-table--filter-footer {
  display: flex;
  justify-content: space-evenly;
}
/deep/ .is--indeterminate.vxe-table--filter-option {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--body-wrapper {
  .vxe-body--x-space {
    display: none;
  }
}
/deep/ .vxe-table--render-default .fixed--hidden {
  visibility: unset !important;
}
/* /deep/ .vxe-table--fixed-left-wrapper .vxe-table--body {
  .vxe-body--row {
    &:last-child {
      .vxe-body--column {
        &:nth-child(2) {
          .vxe-cell {
            min-height: 54px;
            > div {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
} */
/deep/ .vxe-table--fixed-wrapper .vxe-body--row:last-child .vxe-cell {
  height: auto !important;
}
.material-name:hover {
  color: var(--theme-color) !important;
  cursor: pointer;
}
/deep/ .vxe-table--filter-wrapper {
  z-index: 9999;
}
/deep/ .vxe-table--render-default .fixed--hidden {
  visibility: unset !important;
}
.vxe-table--fixed-left-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}
/deep/ .vxe-body--column {
  border-top: 1px solid rgb(233, 232, 232) !important;
}
/deep/ .vxe-table--footer-wrapper {
  border-top: 1px solid rgb(233, 232, 232) !important;
}
/deep/ .vxe-header--row {
  background-color: #f8f8f9;
}
/* /deep/ .vxe-table--fixed-left-wrapper{
  height:auto !important;
  overflow: hidden !important;
  .vxe-table--body-wrapper{
    overflow: hidden !important;
  }
} */
/deep/ .vxe-header--row .vxe-cell {
  font-weight: 400 !important;
}
/deep/ .icon-shaixuan {
  font-size: 16px !important;
  font-weight: 400 !important;
}
/deep/ .icon-shaixuan {
  color: rgba(0, 0, 0, 0.65) !important;
}
/deep/ .filter-product-btn {
  color: var(--theme-color) !important;
}
</style>
